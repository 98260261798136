import { NextPage } from "next";

import LoginPage from "@/pages/auth/login";

const Index: NextPage = () => {
  return <LoginPage />;
};

export default Index;

export const getServerSideProps = async () => ({
  props: { noHeader: true },
});
